import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { QUESTIONNAIRE_ID_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from 'src/app/constants';
import { ChoiceSet } from 'src/app/models/choice-set.model';
import { Question } from 'src/app/models/question.model';
import { Questionnaire } from 'src/app/models/questionnaire.model';
import { PersistanceService } from 'src/app/services/persistance.service';
import { QuestionnairesApiService } from 'src/app/services/questionnaires-api.service';

@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrls: ['./questionnaire-detail.component.css'],
})
export class QuestionnaireDetailComponent implements OnInit {
  questionnaire: Questionnaire;
  currentlyVisibleQuestionIndex: number;
  currentlyVisibleQuestion: Question;
  currentlyVisibleQuestionChoiceSet: ChoiceSet;

  answeredLastQuestion = false;
  successfullySaved = false;
  saveFailed = false;
  answers = {};

  constructor(
    private route: ActivatedRoute,
    private questionnairesApiService: QuestionnairesApiService,
    private router: Router,
    private persistance: PersistanceService,
  ) { }

  ngOnInit() {
    this.loadQuestionnaire();
  }

  loadQuestionnaire() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const token = this.persistance.get(TOKEN_PERSISTENCE_KEY);
      this.questionnairesApiService.getQuestionnaire(Number(params.get(QUESTIONNAIRE_ID_ROUTER_PARAM)), token).pipe(
        take(1),
      ).subscribe((fetchedQuestionnaire: Questionnaire) => {

        this.questionnaire = fetchedQuestionnaire;
        this.prepareAnswers(fetchedQuestionnaire);

        if (!this.currentlyVisibleQuestionIndex) {
          this.currentlyVisibleQuestionIndex = 0;
          this.setCurrentlyVisibleQuestion(0);
        }

      });
    });
  }

  prepareAnswers(questionnaire: Questionnaire) {
    const questions = questionnaire.questions;
    this.answers = questions.reduce((obj, question) => {
      obj[question.id] = undefined;
      return obj;
    }, {});
  }

  setAnswer(questionId: number, choiceId: number) {
    this.answers[questionId] = choiceId;
  }

  goToNextQuestion() {
    const lastQuestion = this.currentlyVisibleQuestionIndex === this.questionnaire.questions.length - 1;
    if (!lastQuestion) {
      this.currentlyVisibleQuestionIndex++;
      this.setCurrentlyVisibleQuestion(this.currentlyVisibleQuestionIndex);
    } else {
      this.answeredLastQuestion = true;
    }
  }

  setCurrentlyVisibleQuestion(index: number) {
    this.currentlyVisibleQuestion = this.questionnaire.questions[index];
    this.currentlyVisibleQuestionChoiceSet = this.questionnaire.choiceSets.find(
      (choiceSet: ChoiceSet) => choiceSet.id === this.currentlyVisibleQuestion.choiceSetId
    );
  }

  onChoiceButtonClick(questionId: number, choiceId: number) {
    this.setAnswer(questionId, choiceId);
    this.goToNextQuestion();
  }

  onSkipButtonClick() {
    this.goToNextQuestion();
  }

  onSaveButtonClick() {
    this.questionnairesApiService.saveQuestionnaire(this.questionnaire.id, this.answers).pipe(
      tap(() => this.finishQuestionnaire()),
      catchError(error => {
        this.saveFailed = true;
        return of(error); // TODO: Do proper error handling in Interceptor when BE is present
      }),
      take(1),
    ).subscribe();
  }

  finishQuestionnaire() {
    this.successfullySaved = true;
    this.persistance.delete(TOKEN_PERSISTENCE_KEY);
  }
}
