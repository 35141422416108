import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChoiceSet } from 'src/app/models/choice-set.model';
import { Choice } from 'src/app/models/choice.model';
import { QuestionType } from 'src/app/models/question-type.model';
import { Question } from 'src/app/models/question.model';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css'],
})
export class QuestionDetailComponent {

  @Input() question: Question;
  @Input() choiceSet: ChoiceSet;
  @Output() choiceButtonClick: EventEmitter<number> = new EventEmitter();
  @Output() skipButtonClick: EventEmitter<undefined> = new EventEmitter();

  QuestionType = QuestionType;

  selectedIndex = 0;

  onSliderValueChange(value: number) {
    this.selectedIndex = Number(value);
  }

  handleSliderValueConfirm() {
    const selectedChoice: Choice = this.choiceSet.choices[this.selectedIndex];
    this.choiceButtonClick.emit(selectedChoice.id);
  }

  handleSkipClick(button) {
    setTimeout(() => {
      button.blur();
      this.skipButtonClick.emit();
    }, 500);
  }

  handleChoiceClick(choiceId: number, button) {
    setTimeout(() => {
      button.blur();
      this.choiceButtonClick.emit(choiceId);
    }, 500);
  }

}
