import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { QUESTIONNAIRES_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from 'src/app/constants';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent {

  constructor(private router: Router) {}

  handleNavigateWithParamsClick() {
    this.router.navigate([QUESTIONNAIRES_ROUTER_PARAM], {queryParams: {[TOKEN_PERSISTENCE_KEY]: 'entitled'}});
  }
 }
