import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { QuestionnaireDetailComponent } from './components/questionnaire-detail/questionnaire-detail.component';
import { QuestionnaireListComponent } from './components/questionnaire-list/questionnaire-list.component';
import { StartComponent } from './components/start/start.component';
import { HasTokenGuard } from './services/has-token-guard.service';
import { MockQuestionnairesApiService } from './services/mock-questionnaires-api.service';
import { PersistanceService } from './services/persistance.service';
import { QuestionnairesApiService } from './services/questionnaires-api.service';
import { StartGuard } from './services/start-guard.service';

@NgModule({
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
   ],
   declarations: [
      AppComponent,
      QuestionnaireListComponent,
      QuestionnaireDetailComponent,
      QuestionDetailComponent,
      StartComponent,
   ],
   providers: [
      {
         provide: QuestionnairesApiService,
         useClass: MockQuestionnairesApiService,
         // useClass: QuestionnairesApiService,
      },
      HasTokenGuard,
      StartGuard,
      PersistanceService,
   ],
   bootstrap: [
      AppComponent,
   ],
})
export class AppModule { }
