import { QuestionnairesApiService } from 'src/app/services/questionnaires-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Questionnaire } from 'src/app/models/questionnaire.model';
import { PersistanceService } from 'src/app/services/persistance.service';
import { TOKEN_PERSISTENCE_KEY, QUESTIONNAIRES_ROUTER_PARAM } from 'src/app/constants';

@Component({
  selector: 'app-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.css'],
})
export class QuestionnaireListComponent implements OnInit {

  public questionnaires: Questionnaire[];

  constructor(
    private questionnairesApiService: QuestionnairesApiService,
    private router: Router,
    private persistance: PersistanceService,
  ) { }

  ngOnInit() {
    this.loadQuestionnaires();
  }

  loadQuestionnaires() {
    const token = this.persistance.get(TOKEN_PERSISTENCE_KEY);
    this.questionnairesApiService.getQuestionnaires(token).pipe(
      take(1)
    ).subscribe((questionnaires: Questionnaire[]) => {
      this.questionnaires = questionnaires;
    });
  }

  handleGoToQuestionaireClick(questionnaire: Questionnaire) {
    this.router.navigate([`${QUESTIONNAIRES_ROUTER_PARAM}/${questionnaire.id}`]);
  }
}
