import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { QUESTIONNAIRES_ROUTER_PARAM, TOKEN_PERSISTENCE_KEY } from './../constants';
import { PersistanceService } from './persistance.service';

@Injectable()
export class StartGuard implements CanActivate {
  constructor(private router: Router, private persistance: PersistanceService) {}

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const hasToken = this.persistance.get(TOKEN_PERSISTENCE_KEY);

    if (!hasToken) {
      return true;
    } else {
      this.router.navigate([QUESTIONNAIRES_ROUTER_PARAM]);
    }
  }
}
