import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

import { QuestionType } from '../models/question-type.model';
import { Questionnaire } from '../models/questionnaire.model';

// tslint:disable:max-line-length
const mockQuestionnaire: Questionnaire = {
  id: 0,
  title: 'QLQ-C30',
  questions: [
    {
      id: 0,
      label: 'Bereitet es Ihnen Schwierigkeiten, sich körperlich anzustrengen (z.B. eine schwere Einkaufstasche oder einen Koffer zu tragen)?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 1,
      label: 'Bereitet es Ihnen Schwierigkeiten, einen längeren Spaziergang zu machen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 2,
      label: 'Bereitet es Ihnen Schwierigkeiten, eine kurze Strecke außer Haus zu gehen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 3,
      label: 'Müssen Sie tagsüber im Bett liegen oder in einem Sessel sitzen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 4,
      label: 'Brauchen Sie Hilfe beim Essen, Anziehen, Waschen oder Benutzen der Toilette?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 5,
      label: 'Waren Sie bei Ihrer Arbeit oder bei anderen tagtäglichen Beschäftigungen eingeschränkt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 6,
      label: 'Waren Sie bei Ihren Hobbys oder anderen Freizeitbeschäftigungen eingeschränkt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 7,
      label: 'Waren Sie kurzatmig?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 8,
      label: 'Hatten Sie Schmerzen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 9,
      label: 'Mussten Sie sich ausruhen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 10,
      label: 'Hatten Sie Schlafstörungen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 11,
      label: 'Fühlten Sie sich schwach?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 12,
      label: 'Hatten Sie Appetitmangel?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 13,
      label: 'War Ihnen übel?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 14,
      label: 'Haben Sie erbrochen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 15,
      label: 'Hatten Sie Verstopfung?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 16,
      label: 'Hatten Sie Durchfall?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 17,
      label: 'Waren Sie müde?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 18,
      label: 'Fühlten Sie sich durch Schmerzen in Ihrem alltäglichen Leben beeinträchtigt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 19,
      label: 'Hatten Sie Schwierigkeiten, sich auf etwas zu konzentrieren, z. B. auf Zeitunglesen oder Fernsehen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 20,
      label: 'Fühlten Sie sich angespannt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 21,
      label: 'Haben Sie sich Sorgen gemacht?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 22,
      label: 'Waren Sie reizbar?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 23,
      label: 'Fühlten Sie sich niedergeschlagen?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 24,
      label: 'Hatten Sie Schwierigkeiten, sich an Dinge zu erinnern?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 25,
      label: 'Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung Ihr Familienleben beeinträchtigt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 26,
      label: 'Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung Ihr Zusammensein oder Ihre gemeinsamen Unternehmungen mit anderen Menschen beeinträchtigt?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 27,
      label: 'Hat Ihr körperlicher Zustand oder Ihre medizinische Behandlung für Sie finanzielle Schwierigkeiten mit sich gebracht?',
      type: QuestionType.BUTTONS,
      choiceSetId: 0,
    },
    {
      id: 28,
      label: 'Wie würden Sie insgesamt Ihren Gesundheitszustand während der letzten Woche einschätzen?',
      type: QuestionType.SLIDER,
      choiceSetId: 1,
    },
    {
      id: 29,
      label: 'Wie würden Sie insgesamt Ihre Lebensqualität während der letzten Woche einschätzen?',
      type: QuestionType.SLIDER,
      choiceSetId: 1,
    },
  ],
  choiceSets: [
    {
      id: 0,
      choices: [
        {
          id: 0,
          label: 'Überhaupt nicht',
          valueNumber: 1,
        },
        {
          id: 1,
          label: 'Wenig',
          valueNumber: 2,
        },
        {
          id: 2,
          label: 'Mäßig',
          valueNumber: 3,
        },
        {
          id: 3,
          label: 'Sehr',
          valueNumber: 4,
        },
      ],
    },
    {
      id: 1,
      choices: [
        {
          id: 0,
          label: 'sehr schlecht',
          valueNumber: 1,
        },
        {
          id: 1,
          valueNumber: 2,
        },
        {
          id: 2,
          valueNumber: 3,
        },
        {
          id: 3,
          valueNumber: 4,
        },
        {
          id: 4,
          valueNumber: 5,
        },
        {
          id: 5,
          valueNumber: 6,
        },
        {
          id: 6,
          label: 'ausgezeichnet',
          valueNumber: 7,
        },
      ],
    },
  ],
};

const mockQuestionnaires: Questionnaire[] = [
  mockQuestionnaire,
/*   {
    id: 1,
    title: 'Test2',
    questions: [],
    choiceSets: [],
  }, */
];

const err: HttpErrorResponse = new HttpErrorResponse({ status: 418 });

const responseDelay = 1000;

@Injectable({
  providedIn: 'root',
})
export class MockQuestionnairesApiService {

  constructor() { }

  public getQuestionnaires(token: string): Observable<Questionnaire[]> {
    console.log(`fetching questionnaire list with token ${token}`);
    return of(mockQuestionnaires).pipe(delay(responseDelay));
  }

  public getQuestionnaire(questionnaireId: number, token: string): Observable<Questionnaire> {
    console.log(`fetching questionnaire ${questionnaireId} with token ${token}`);
    return of(mockQuestionnaire).pipe(delay(responseDelay));
  }

  public saveQuestionnaire(questionnaireId: number, answers): Observable<{}> {
    console.log(`saving questionnaire ${questionnaireId} with answers`, answers);
    const shouldError = false;
    return shouldError
      ? throwError(err).pipe(delay(responseDelay))
      : of({}).pipe(delay(responseDelay));
  }

}
