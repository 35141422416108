import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { TOKEN_PERSISTENCE_KEY } from './../constants';
import { PersistanceService } from './persistance.service';

@Injectable()
export class HasTokenGuard implements CanActivate {
  constructor(private router: Router, private persistance: PersistanceService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const queryParams = route.queryParams;
    const token = queryParams.token;

    const hasOldToken = this.persistance.get(TOKEN_PERSISTENCE_KEY);

    if (token || hasOldToken) {
      if (token) {
        this.persistance.set(TOKEN_PERSISTENCE_KEY, token);
      }
      return true;
    } else {
      this.router.navigate([]);
    }
  }
}
