import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Questionnaire } from '../models/questionnaire.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionnairesApiService {

  API_BASE = 'esprioBackend';

  constructor(public client: HttpClient) { }

  public getQuestionnaires(token: string): Observable<Questionnaire[]> {
    return this.client.post<Questionnaire[]>(`${this.API_BASE}/questionnaires`, token);
  }

  public getQuestionnaire(questionnaireId: number, token: string): Observable<Questionnaire> {
    return this.client.post<Questionnaire>(`${this.API_BASE}/questionnaires/${questionnaireId}`, token);
  }

  public saveQuestionnaire(questionnaireId: number, answers) {
    return this.client.post<{}>(`${this.API_BASE}/questionnaires/${questionnaireId}`, answers);
  }
}
